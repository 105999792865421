import React from "react";
import { useHistory, useLocation } from "react-router";

import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

const Submenu = () => {
  const { replace, push } = useHistory();
  const { pathname } = useLocation();

  const handleClick = route => {
    if (pathname === route) {
      replace(route);
    } else {
      push(route);
    }

    window.scrollTo(0, 0);
  };

  return (
    <List className="submenu" disablePadding>
      <ListItem button onClick={() => handleClick("/")}>
        <ListItemText primary="Hashtag 2024" />
      </ListItem>

      <ListItem button onClick={() => handleClick("/2023")}>
        <ListItemText primary="Hashtag 2023" />
      </ListItem>

      <Divider />

      <ListItem button onClick={() => handleClick("/2022")}>
        <ListItemText primary="Hashtag 2022" />
      </ListItem>

      <Divider />

      <ListItem button onClick={() => handleClick("/2021")}>
        <ListItemText primary="Hashtag 2021" />
      </ListItem>

      <Divider />

      <ListItem button onClick={() => handleClick("/2020")}>
        <ListItemText primary="Hashtag 2020" />
      </ListItem>
    </List>
  );
};

export default Submenu;
