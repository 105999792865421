import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles(theme => ({
    root: {
      color: theme.palette.common.white,
      objectFit: "contain",
      backgroundSize: "cover",
      backgroundPosition: "center top",
      position: "relative",
      margin: theme.spacing(4, 8, 0),
      [theme.breakpoints.down("sm")]: {
        margin: theme.spacing(2, 2, 0)
      }
    },
    content: {
      color: theme.palette.primary.main,
      marginBottom: theme.spacing(7),
      [theme.breakpoints.down("sm")]: {
        marginBottom: theme.spacing(4)
      }
    },
    companyName: {
      fontSize: 40,
      textAlign: "center",
      textTransform: "uppercase",
      [theme.breakpoints.down("sm")]: {
        fontSize: 32
      }
    },
    description: {
      fontSize: 20,
      [theme.breakpoints.down("sm")]: {
        fontSize: 16
      }
    },
    flexbox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flexWrap: "wrap"
    },
    bannerImg: {
      width: "100%"
    }
  }));

const Blurb = () => {
    const classes = useStyles();

    return(
        <div className={classes.root}>
            <div className={classes.content}>
              <img className={classes.bannerImg} src="https://res.cloudinary.com/startsomething-com-sg/image/upload/v1732783132/wavemaker/banner_2024_g6spns.png"></img>
              <p className={classes.description}>#Wavemakers is Wavemaker Partners’ annual Startup Showcase where select portfolio companies present their game-changing tech solutions in front of investors, corporates, government and media representatives, and other partners in the Wavemaker ecosystem.</p>
              <p className={classes.description}>Wavemaker Partners is Southeast Asia's leading venture capital firm investing and building solutions in Enterprise, Deep Tech, and Sustainability. Since 2012, it has backed over 200 companies across the region, with over US$2 billion enterprise value in exits and over US$600 million in assets under management (AUM) across multiple funds, including Wavemaker Impact (climatetech venture builder), Wavemaker Ventures (early-stage fund), and Wavemaker Growth (growth fund). Notable limited partners include Pavilion Capital, Temasek Holdings, the International Finance Corporation, DEG (investment arm of Germany’s state-owned development bank KfW), Cercano Management, the US International Development Finance Corporation, British International Investment, and Qarlbo Energy.</p>
              <div className="divider-container">
                <Divider className="divider" light />
              </div>
            </div>     
        </div>
    )
}

export default Blurb;