import React, { useState } from "react";
import { useHistory, useLocation } from "react-router";
import { useSelector, useDispatch } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import EditIcon from "@material-ui/icons/Edit";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";

import {
  openSelector,
  toggleEditProfileDialog,
  toggleLoginDialog,
  toggleNavDrawer,
  toggleSnackbar
} from "Slices/openSlice";
import { logoutUser, userSelector } from "Slices/userSlice";
import { setSnackbarMessage } from "Slices/contentSlice";

const useStyles = makeStyles(theme => ({
  list: {
    width: 240
  },
  nestedList: {
    paddingLeft: theme.spacing(4)
  }
}));

const NavDrawer = () => {
  const classes = useStyles();
  const { replace, push } = useHistory();
  const { pathname } = useLocation();
  const { navDrawer } = useSelector(openSelector);
  const { token } = useSelector(userSelector);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const handleClick = route => {
    if (route) {
      if (route.includes("https")) {
        window.open(route, "_blank", "noopener, noreferrer");
      } else {
        if (pathname === route) {
          replace(route);
        } else {
          push(route);
        }

        window.scrollTo(0, 0);
      }

      handleClose();
    } else {
      setOpen(!open);
    }
  };

  const handleClose = () => {
    dispatch(toggleNavDrawer());
  };

  const handleOpenDialog = () => {
    handleClose();
    dispatch(toggleLoginDialog());
  };

  const handleLogout = () => {
    handleClose();
    dispatch(logoutUser());
    dispatch(setSnackbarMessage("Logout successful!"));
    dispatch(toggleSnackbar());
  };

  const handleEditProfile = () => {
    handleClose();
    dispatch(toggleEditProfileDialog());
  };

  return (
    <Drawer anchor="left" open={navDrawer} onClose={handleClose}>
      <List className={classes.list} disablePadding>
        <ListItem button onClick={() => handleClick()}>
          <ListItemText primary="Hashtag Home" />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List disablePadding>

            <ListItem
              button
              className={classes.nestedList}
              onClick={() => handleClick("/")}
            >
              <ListItemText primary="Hashtag 2024" />
            </ListItem>

            <ListItem
              button
              className={classes.nestedList}
              onClick={() => handleClick("/2023")}
            >
              <ListItemText primary="Hashtag 2023" />
            </ListItem>

            <ListItem
              button
              className={classes.nestedList}
              onClick={() => handleClick("/2022")}
            >
              <ListItemText primary="Hashtag 2022" />
            </ListItem>

            <ListItem
              button
              className={classes.nestedList}
              onClick={() => handleClick("/2021")}
            >
              <ListItemText primary="Hashtag 2021" />
            </ListItem>

            <ListItem
              button
              className={classes.nestedList}
              onClick={() => handleClick("/2020")}
            >
              <ListItemText primary="Hashtag 2020" />
            </ListItem>
          </List>
        </Collapse>

        <ListItem button onClick={() => handleClick("/faq")}>
          <ListItemText primary="FAQ" />
        </ListItem>

        <ListItem
          button
          onClick={() => handleClick("https://wavemaker.vc/sea/")}
        >
          <ListItemText primary="Wavemaker SEA" />
        </ListItem>

        <ListItem
          button
          onClick={() => handleClick("https://wavemaker.vc/sea/team")}
        >
          <ListItemText primary="Meet the Team" />
        </ListItem>

        <Divider />

        {token ? (
          <>
            <ListItem button onClick={handleEditProfile}>
              <ListItemIcon>
                <EditIcon />
              </ListItemIcon>
              <ListItemText primary="Edit Profile" />
            </ListItem>

            <ListItem button onClick={handleLogout}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </>
        ) : (
          <ListItem button onClick={handleOpenDialog}>
            <ListItemIcon>
              <PersonOutlineIcon />
            </ListItemIcon>
            <ListItemText primary="Sign In to View" />
          </ListItem>
        )}
      </List>
    </Drawer>
  );
};

export default NavDrawer;
